.wrapper {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.top {
  text-align: center;
}
.top > h2 {
  font-weight: 700;
  font-size: 40px;
}

.top > span {
  font-size: 22px;
  font-weight: 400;
}

.middle {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.bottom > img {
  margin-top: 20px;
  width: 614px;
  height: 590px;
  object-fit: cover;
}
.button {
  border: none;
  font-weight: 500;
  font-size: 16px;
  width: 200px;
  height: 35px;
  background-color: #c0bfc0;
  border-radius: 25px;
  cursor: pointer;
}

.button:hover {
  background-color: #8f39ff;
  color: white;
}

.sec4_selected {
  background-color: #8f39ff;
  color: white;
}

@media screen and (max-width: 768px) {
  .top > h2 {
    font-weight: 700;
    font-size: 24px;
  }
  .top > span {
    font-size: 16px;
    font-weight: 400;
  }

  .middle {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  .button {
    border: none;
    font-weight: 500;
    font-size: 15px;
    width: 150px;
    background-color: #c0bfc0;
    border-radius: 25px;
    cursor: pointer;
  }

  .bottom > img {
    margin-top: 20px;
    width: 300px;
    height: 250px;
    object-fit: cover;
  }
}
