.Tabs {
  text-align: center;
}
.Tab {
  display: flex;
  justify-content: space-evenly;
}
@media screen and (max-width: 768px) {
  .Tab {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.left-title {
  font-size: 18px;
  font-weight: 700;
}

.left-desc {
  font-weight: 600;
  font-size: 16px;
}

.left-button {
  color: white;
  border: none;
  min-width: 150px;
  height: 35px;
  background-color: #01b187;
  border-radius: 20px;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}

.left-button:hover {
  background-color: #03916f;
}

/* tab color */

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #6c63ff;
}
