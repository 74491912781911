@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", "Eudoxus Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.smooth-transition {
  transition: all 0.3s ease-in-out;
}

.active {
  background-color: #7d75fe;
  color: white;
}

.gradient {
  background: linear-gradient(90deg, #6c63ff 0%, #a938d0 46.35%, #ef069a 100%);
}
