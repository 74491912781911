/* @import "~antd/dist/antd.css"; */

.care {
  margin-left: 4rem;
  margin-right: 4rem;
  overflow-x: hidden;
}

.layout {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

/* .navbar-align {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
} */
.nav-menu-align {
  margin-right: 15px;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  /* font-family: "poppins"; */
  cursor: pointer;
}
.logo {
  width: 11rem;
  height: 7rem;
  margin-right: 22rem;
}
.nav-book {
  border-radius: 16px;
  width: 12rem;
  height: 2rem;
  color: white;
  background-color: #8f39ff;
  border-width: 0px;
}

/*care page*/
.care-content {
  /* width: 44rem; */
  padding: 9px;
  /* margin-bottom: 17rem; */
  display: flex;
  justify-content: space-evenly;
}
.content-head {
  font-size: 40px;
  line-height: 1.4;
}
.content {
  font-size: 23px;
  width: 33rem;
}
.care-logo-container {
  position: absolute;
  top: 3px;
  left: 30rem;
}
.care-logo {
  width: 800px;
  margin-top: -54px;
  margin-left: -143px;
}

/*retail menu*/
.retail-menu-align {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
.retail-container {
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
}
.retail-logo {
  width: 4rem;
}
.retail-head {
  color: #8f39ff;
  font-weight: bold;
  font-size: 14px;
}

.provider-head {
  color: #333333;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 7px;
}
.care-logo-img {
  width: 3rem;
  margin-left: 30px;
}

@media only screen and (max-width: 961px) {
  .care {
    margin-left: 25px;
    margin-right: 25px;
  }
  .navbar-align {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
  }
  .nav-menu-align {
    margin-right: 10px;
    font-size: 7px;
    font-style: normal;
    font-weight: bold;
    /* font-family: "poppins"; */
    cursor: pointer;
  }
  .logo {
    width: 5rem;
    height: 6rem;
    margin-left: 26px;
    margin-right: 12px;
  }
  .nav-book {
    border-radius: 16px;
    width: 80px;
    height: 2rem;
    color: white;
    background-color: #8f39ff;
    border-width: 0px;
  }
  .care-content {
    width: 24rem;
    padding: 9px;
    /* margin-bottom: 17rem; */
    display: flex;
    flex-wrap: wrap;
  }
  .content-head {
    font-size: 19px;
    line-height: 1.4;
  }
  .content {
    font-size: 14px;
    width: 22rem;
  }
  .care-logo-container {
    position: absolute;
    top: 16rem;
    left: 2rem;
  }
  .care-logo {
    width: 22rem;
    margin-left: 23px;
  }
  .retail-container {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    align-items: center;
  }
  .retail-logo {
    width: 3rem;
  }
  .retail-head {
    color: #8f39ff;
    font-weight: bold;
    font-size: 13px;
  }
}

/*Care page footer*/

.care-page-footer {
  background-color: #f9fafb;
  padding: 5px;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.read_cards {
  display: flex;
  margin-top: -53rem;
  flex-wrap: wrap;
  margin-left: 11rem;
}
.footer-container {
  display: flex;

  margin-right: 16px;
}
.footer-align {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.foot-content-head {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
}
.foot-content {
  margin-top: 5px;
  font-size: 12px;
}
.service-content {
  text-align: initial;
  font-size: 15px;
}
.read-more {
  color: #8f39ff;
  text-align: initial;
  cursor: pointer;
}

/*CarepageFooterTwo*/

.care-page-footer2 {
  background-color: white;
  padding: 5px;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* tab */
/* .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  overflow: hidden;
  align-items: stretch;
  transform: translate(0);
  font-weight: 600;
  font-size: xx-large;
} */
.flowchart-logo {
  width: 52rem;
}
.home_footer_2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 17px;
  justify-content: space-around;
}
.unified-logo {
  width: 40rem;
}
.home_footer2_container {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.home_footer2_list {
  display: flex;
  border: none;
  background-color: transparent;
}
.claim_button {
  border-radius: 18px;
  background-color: #01b187;
  border: none;
  /* padding: 9px; */
  padding: 8px 15px 8px 15px;
  color: white;
  font-weight: 600;
}

/*read more page*/
.readmore-container {
  position: relative;
  top: 0;
  z-index: -1;
  left: 2px;
  max-width: 107rem;
}
.readone-head {
  font-size: 27px;
  color: white;
  font-weight: bold;
}
.readone-content {
  font-size: 16px;
  color: white;
}
.readmore-tech {
  /* position: absolute; */
  /* top: 53rem; */
  margin-top: 27rem;
  text-align: center;

  /* right: 14rem; */
}
.readmore-tech_2 {
  /* position: absolute;  */
  /* top: 53rem; */
  margin-top: 3rem;
  /* text-align: center; */
  background-color: "#F9FAFB";
  /* right: 14rem; */
  align-items: center;
  display: flex;
  flex-direction: column;
}
.readmore-tech_3 {
  /* position: absolute; */
  /* top: 53rem; */
  margin-top: 3rem;
  text-align: center;

  /* right: 14rem; */
}
.tech-head {
  font-size: 33px;
  font-weight: bold;
}
.tech-head__2 {
  font-size: 20px;
  font-weight: bold;
}
.tech-content {
  font-size: 20px;
}
.tech-content3 {
  font-size: 15px;
}
.tech-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /* width: 60rem; */
  text-align: center;
}
.tech-cards_3 {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  /* width: 73rem; */
  text-align: center;
}
.tech-box {
  padding: 11px;
  max-width: 20rem;
}
.tech-box_3 {
  padding: 6px;
  width: 20rem;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  height: 13rem;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  margin-right: 11rem;
}
.tech-box-large {
  padding: 11px;
  max-width: 20rem;
  display: flex;
}
.tech-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tech-title2 {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tech-mainhead {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 13px;
}
.tech-mainhead3 {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 13px;
}
.tech-logo {
  margin-right: 18px;
  width: 10rem;
}
.tech-logo3 {
  /* margin-right: 18px; */
  width: 4rem;
}
.tech-card-detail {
  font-size: 10px;
  margin-top: 15px;
}
.tech-card-detail3 {
  font-size: 12px;
  margin-top: 6px;
}
.mobileVisible {
  display: none !important;
}
.mobileHidden {
  display: block;
}
.read_flow_image {
  margin-right: 7rem;
  width: 39rem;
}
@media only screen and (max-width: 961px) {
  .read_cards {
    display: flex;
    margin-top: 6rem;
    flex-wrap: wrap;
    margin-left: 0rem;
  }
  .footer-align {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    flex-wrap: wrap;
  }
  /* tab */
  /* .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    position: relative;
    left: 0rem;
    display: inline-block;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);
    font-weight: 600;
    justify-content: center;
  }
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 8px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  } */
  .flowchart-logo {
    width: 22rem;
  }
  .unified-logo {
    width: 22rem;
  }
  .home_footer2_container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .readmore-container {
    display: none;
    max-width: 100rem;
  }
  .readone-head {
    font-size: 19px;
    color: black;
    font-weight: bold;
  }
  .readone-content {
    font-size: 14px;
    color: black;
  }
  .read_flow_image {
    margin-right: 1px;
    width: 20rem;
  }
  .readmore-tech {
    /* position: absolute; */
    /* top: 53rem; */
    margin-top: 3rem;
    text-align: center;

    /* right: 14rem; */
  }
  .tech-head {
    font-size: 22px;
    font-weight: bold;
  }
  .tech-content {
    font-size: 15px;
    width: 21rem;
  }
  .tech-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* width: 60rem; */
    text-align: center;
    margin-left: 20px;
  }
  .tech-cards_3 {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
    /* width: 73rem; */
    text-align: center;
  }
  .tech-box_3 {
    padding: 6px;
    width: 20rem;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
    height: 13rem;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    margin-right: 0rem;
  }
}

/* indian site carusel */
/* .ezIMYv {
  grid-gap: 0 !important;
} */
