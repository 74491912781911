.sec5_container {
  padding: 35px;
}

.sec5_top {
  text-align: center;
  margin-bottom: 40px;
}

.sec5_top > h2 {
  font-weight: 700;
  font-size: 20px;
}

.sec5_top > span {
  font-size: 16px;
  font-weight: 400;
}

.sec5_bottom {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .sec5_bottom {
    display: flex;
    flex-direction: column;
  }
}

.sec5_bottom_left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #eef0ff;
  color: black;
  margin-bottom: 10px;
  width: 280px;
  padding: 2px 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sec5_bottom_left:hover {
  transition: ease-in-out;
  border: none;
  color: white;
  background: linear-gradient(90deg, #6c63ff 0%, #ef069a 88.23%);
}
.sec5_selected {
  color: white;
  background: linear-gradient(90deg, #6c63ff 0%, #ef069a 88.23%);
  border: none;
}

.sec5_bottom_right_img {
  width: 650px;
  height: 450px;
}
